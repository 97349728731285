import * as React from 'react';
import { SVGProps } from 'react';

const SvgVerifyBonusIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={47}
      height={47}
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m41.34 23.13-8.898-1.293-3.978-8.065c-.109-.22-.287-.4-.508-.508a1.126 1.126 0 0 0-1.504.508l-3.978 8.065-8.899 1.293a1.12 1.12 0 0 0-.62 1.914l6.438 6.277-1.52 8.864a1.12 1.12 0 0 0 1.626 1.18l7.959-4.184 7.96 4.185a1.12 1.12 0 0 0 1.626-1.181l-1.521-8.864 6.438-6.277a1.12 1.12 0 0 0 .326-.642 1.117 1.117 0 0 0-.946-1.272Z"
        fill="#4E261E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.334 28.699a2.203 2.203 0 0 0-.628-.72 4.17 4.17 0 0 0-.957-.522c-.37-.147-.774-.287-1.211-.421a15.195 15.195 0 0 1-.598-.192 2.518 2.518 0 0 1-.43-.19.85.85 0 0 1-.26-.217.456.456 0 0 1-.086-.279c0-.106.026-.197.08-.271a.587.587 0 0 1 .216-.181 1.14 1.14 0 0 1 .317-.1c.12-.021.247-.032.383-.032.133 0 .262.018.39.054a2.345 2.345 0 0 1 .691.324c.102.07.193.136.276.201l.055.046c.058.046.11.084.159.114.047.031.1.047.158.047.07 0 .14-.033.21-.098l.947-.935a.904.904 0 0 0 .121-.143.249.249 0 0 0 .04-.137c0-.05-.027-.11-.083-.178a1.695 1.695 0 0 0-.226-.221 3.486 3.486 0 0 0-.331-.238 7.226 7.226 0 0 0-.4-.235 4.142 4.142 0 0 0-1.018-.375c-.105-.024-.213-.037-.32-.053v-.776a.212.212 0 0 0-.102-.183.354.354 0 0 0-.195-.069h-1.165c-.09 0-.158.025-.203.075a.252.252 0 0 0-.07.177v.805c-.128.023-.262.042-.383.075-.404.113-.75.273-1.038.48a2.171 2.171 0 0 0-.661.744c-.156.29-.233.614-.233.97 0 .332.062.635.186.91.123.276.309.527.557.755.248.227.558.434.929.619.372.185.805.358 1.3.519.112.034.244.078.397.129.153.051.3.113.44.186s.259.155.356.25c.097.093.146.2.146.317a.882.882 0 0 1-.053.305.621.621 0 0 1-.177.252.91.91 0 0 1-.338.174 1.806 1.806 0 0 1-.528.067c-.228 0-.445-.037-.655-.11a3.082 3.082 0 0 1-.621-.303 3.683 3.683 0 0 1-.375-.267c-.11-.09-.2-.161-.27-.215a1.163 1.163 0 0 0-.148-.103.247.247 0 0 0-.118-.035c-.054 0-.124.03-.211.086l-1.071.947c-.095.08-.142.152-.142.217 0 .061.047.138.142.23l.025.023.024.023.019.017c.198.202.427.392.684.567.258.176.533.33.824.462.29.132.595.235.91.31.028.006.056.008.084.014v.843c0 .168.091.252.272.252h1.165c.198 0 .297-.084.297-.252v-.825c.186-.032.37-.066.545-.118a3.683 3.683 0 0 0 1.143-.56 2.67 2.67 0 0 0 .765-.874c.185-.342.278-.723.278-1.143 0-.398-.075-.737-.226-1.015"
        fill="#2D3344"
      />
      <path
        d="m35.34 16.914-9.492-1.38-4.243-8.602a1.19 1.19 0 0 0-.543-.542 1.201 1.201 0 0 0-1.603.542l-4.243 8.602-9.493 1.38a1.194 1.194 0 0 0-.661 2.041l6.867 6.696-1.622 9.454a1.194 1.194 0 0 0 1.734 1.26l8.49-4.464 8.49 4.464a1.194 1.194 0 0 0 1.735-1.26l-1.622-9.454L36 18.955c.187-.183.31-.422.348-.684.101-.654-.355-1.26-1.01-1.357Z"
        fill="url(#a)"
      />
      <path
        d="M25.012 21.53v1.988h-8.438V21.53h8.438Zm-3.146-3.39v8.963h-2.139V18.14h2.14Z"
        fill="#FDD9CE"
      />
      <defs>
        <linearGradient
          id="a"
          x1={9.398}
          y1={6.267}
          x2={32.096}
          y2={39.049}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4CA4FF" />
          <stop offset={0.502} stopColor="#9E12AA" />
          <stop offset={1} stopColor="#FB674C" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default SvgVerifyBonusIcon;
