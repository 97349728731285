import { ImageMap } from '../contracts';

import account_icon, {
  ReactComponent as JSXaccount_icon,
} from './images/account_icon.svg';
import api_icon, { ReactComponent as JSXapi_icon } from './images/api_icon.svg';
import api_illustration, {
  ReactComponent as JSXapi_illustration,
} from './images/api_illustration.svg';
import api_new_icon, {
  ReactComponent as JSXapi_new_icon,
} from './images/api_new_icon.svg';
import assist_icon, {
  ReactComponent as JSXassist_icon,
} from './images/assist_icon.svg';
import bank_card, {
  ReactComponent as JSXbank_card,
} from './images/bank_card.svg';
import bonus_balance_icon, {
  ReactComponent as JSXbonus_balance_icon,
} from './images/bonus_balance_icon.svg';
import bonus_history_icon, {
  ReactComponent as JSXbonus_history_icon,
} from './images/bonus_history_icon.svg';
import bonus_icon, {
  ReactComponent as JSXbonus_icon,
} from './images/bonus_icon.svg';
import bonus_nav_icon, {
  ReactComponent as JSXbonus_nav_icon,
} from './images/bonus_nav_icon.svg';
import book_mark_icon, {
  ReactComponent as JSXbook_mark_icon,
} from './images/book_mark_icon.svg';
import bronze_status, {
  ReactComponent as JSXbronze_status,
} from './images/bronze_status.svg';
import card_icon, {
  ReactComponent as JSXcard_icon,
} from './images/card_icon.svg';
import chart_line_icon, {
  ReactComponent as JSXchart_line_icon,
} from './images/chart_line_icon.svg';
import check_icon, {
  ReactComponent as JSXcheck_icon,
} from './images/check_icon.svg';
import close_icon, {
  ReactComponent as JSXclose_icon,
} from './images/close_icon.svg';
import contacts_icon, {
  ReactComponent as JSXcontacts_icon,
} from './images/contacts_icon.svg';
import copy_icon, {
  ReactComponent as JSXcopy_icon,
} from './images/copy_icon.svg';
import course_icon, {
  ReactComponent as JSXcourse_icon,
} from './images/course_icon.svg';
import credit_card_icon, {
  ReactComponent as JSXcredit_card_icon,
} from './images/credit_card_icon.svg';
import cross_arrows_icon, {
  ReactComponent as JSXcross_arrows_icon,
} from './images/cross_arrows_icon.svg';
import cross_arrows_icon_secondary, {
  ReactComponent as JSXcross_arrows_icon_secondary,
} from './images/cross_arrows_icon_secondary.svg';
import crypto_check_icon, {
  ReactComponent as JSXcrypto_check_icon,
} from './images/crypto_check_icon.svg';
import crypto_check_illustration, {
  ReactComponent as JSXcrypto_check_illustration,
} from './images/crypto_check_illustration.svg';
import crypto_image, {
  ReactComponent as JSXcrypto_image,
} from './images/crypto_image.svg';
import crystal, { ReactComponent as JSXcrystal } from './images/crystal.svg';
import crystals1x, {
  ReactComponent as JSXcrystals1x,
} from './images/crystals1x.svg';
import crystals20x, {
  ReactComponent as JSXcrystals20x,
} from './images/crystals20x.svg';
import crystals100x, {
  ReactComponent as JSXcrystals100x,
} from './images/crystals100x.svg';
import crystals, { ReactComponent as JSXcrystals } from './images/crystals.svg';
import crystals_success, {
  ReactComponent as JSXcrystals_success,
} from './images/crystals_success.svg';
import dashboard_icon, {
  ReactComponent as JSXdashboard_icon,
} from './images/dashboard_icon.svg';
import delete_icon, {
  ReactComponent as JSXdelete_icon,
} from './images/delete_icon.svg';
import diamond_aml_icon, {
  ReactComponent as JSXdiamond_aml_icon,
} from './images/diamond_aml_icon.svg';
import diamond_icon, {
  ReactComponent as JSXdiamond_icon,
} from './images/diamond_icon.svg';
import diamond_status, {
  ReactComponent as JSXdiamond_status,
} from './images/diamond_status.svg';
import discount_empty_icon, {
  ReactComponent as JSXdiscount_empty_icon,
} from './images/discount_empty_icon.svg';
import down_arrow_icon, {
  ReactComponent as JSXdown_arrow_icon,
} from './images/down_arrow_icon.svg';
import edit_icon, {
  ReactComponent as JSXedit_icon,
} from './images/edit_icon.svg';
import empty_table_illustration, {
  ReactComponent as JSXempty_table_illustration,
} from './images/empty_table_illustration.svg';
import error_icon, {
  ReactComponent as JSXerror_icon,
} from './images/error_icon.svg';
import exam_card_icon, {
  ReactComponent as JSXexam_card_icon,
} from './images/exam_card_icon.svg';
import exchange_history_icon, {
  ReactComponent as JSXexchange_history_icon,
} from './images/exchange_history_icon.svg';
import exchange_icon, {
  ReactComponent as JSXexchange_icon,
} from './images/exchange_icon.svg';
import factor1x, { ReactComponent as JSXfactor1x } from './images/factor1x.svg';
import factor2x, { ReactComponent as JSXfactor2x } from './images/factor2x.svg';
import factor3x, { ReactComponent as JSXfactor3x } from './images/factor3x.svg';
import factor4x, { ReactComponent as JSXfactor4x } from './images/factor4x.svg';
import factor5x, { ReactComponent as JSXfactor5x } from './images/factor5x.svg';
import factor6x, { ReactComponent as JSXfactor6x } from './images/factor6x.svg';
import factor7x, { ReactComponent as JSXfactor7x } from './images/factor7x.svg';
import faq_icon, { ReactComponent as JSXfaq_icon } from './images/faq_icon.svg';
import file_icon, {
  ReactComponent as JSXfile_icon,
} from './images/file_icon.svg';
import file_text_icon, {
  ReactComponent as JSXfile_text_icon,
} from './images/file_text_icon.svg';
import fortune_wheel, {
  ReactComponent as JSXfortune_wheel,
} from './images/fortune_wheel.svg';
import gift_icon, {
  ReactComponent as JSXgift_icon,
} from './images/gift_icon.svg';
import gold_status, {
  ReactComponent as JSXgold_status,
} from './images/gold_status.svg';
import graph, { ReactComponent as JSXgraph } from './images/graph.svg';
import heart_icon, {
  ReactComponent as JSXheart_icon,
} from './images/heart_icon.svg';
import info_icon, {
  ReactComponent as JSXinfo_icon,
} from './images/info_icon.svg';
import info_icon_secondary, {
  ReactComponent as JSXinfo_icon_secondary,
} from './images/info_icon_secondary.svg';
import jivo_mobile, {
  ReactComponent as JSXjivo_mobile,
} from './images/jivo_mobile.svg';
import key_return_icon, {
  ReactComponent as JSXkey_return_icon,
} from './images/key_return_icon.svg';
import liveness_icon, {
  ReactComponent as JSXliveness_icon,
} from './images/liveness_icon.svg';
import loader, { ReactComponent as JSXloader } from './images/loader.svg';
import logo, { ReactComponent as JSXlogo } from './images/logo.svg';
import logo_mobile, {
  ReactComponent as JSXlogo_mobile,
} from './images/logo_mobile.svg';
import lottery, { ReactComponent as JSXlottery } from './images/lottery.svg';
import magic_wand_icon, {
  ReactComponent as JSXmagic_wand_icon,
} from './images/magic_wand_icon.svg';
import main_bg, { ReactComponent as JSXmain_bg } from './images/main_bg.svg';
import main_bg_mobile, {
  ReactComponent as JSXmain_bg_mobile,
} from './images/main_bg_mobile.svg';
import money_circulation_icon, {
  ReactComponent as JSXmoney_circulation_icon,
} from './images/money_circulation_icon.svg';
import more_menu_icon, {
  ReactComponent as JSXmore_menu_icon,
} from './images/more_menu_icon.svg';
import not_verify_factor1x, {
  ReactComponent as JSXnot_verify_factor1x,
} from './images/not_verify_factor1x.svg';
import not_verify_factor2x, {
  ReactComponent as JSXnot_verify_factor2x,
} from './images/not_verify_factor2x.svg';
import not_verify_factor3x, {
  ReactComponent as JSXnot_verify_factor3x,
} from './images/not_verify_factor3x.svg';
import not_verify_factor4x, {
  ReactComponent as JSXnot_verify_factor4x,
} from './images/not_verify_factor4x.svg';
import not_verify_factor5x, {
  ReactComponent as JSXnot_verify_factor5x,
} from './images/not_verify_factor5x.svg';
import notification_bonuses, {
  ReactComponent as JSXnotification_bonuses,
} from './images/notification_bonuses.svg';
import notification_crypto, {
  ReactComponent as JSXnotification_crypto,
} from './images/notification_crypto.svg';
import notification_giveaway, {
  ReactComponent as JSXnotification_giveaway,
} from './images/notification_giveaway.svg';
import notification_system, {
  ReactComponent as JSXnotification_system,
} from './images/notification_system.svg';
import notification_telegram, {
  ReactComponent as JSXnotification_telegram,
} from './images/notification_telegram.svg';
import partners_icon, {
  ReactComponent as JSXpartners_icon,
} from './images/partners_icon.svg';
import passport_icon, {
  ReactComponent as JSXpassport_icon,
} from './images/passport_icon.svg';
import percent, { ReactComponent as JSXpercent } from './images/percent.svg';
import phone_profile_illustration, {
  ReactComponent as JSXphone_profile_illustration,
} from './images/phone_profile_illustration.svg';
import piggy_icon, {
  ReactComponent as JSXpiggy_icon,
} from './images/piggy_icon.svg';
import platinum_status, {
  ReactComponent as JSXplatinum_status,
} from './images/platinum_status.svg';
import profile_icon, {
  ReactComponent as JSXprofile_icon,
} from './images/profile_icon.svg';
import qr_icon, { ReactComponent as JSXqr_icon } from './images/qr_icon.svg';
import recent_exchanges_icon, {
  ReactComponent as JSXrecent_exchanges_icon,
} from './images/recent_exchanges_icon.svg';
import redirect_icon, {
  ReactComponent as JSXredirect_icon,
} from './images/redirect_icon.svg';
import referral_illustration, {
  ReactComponent as JSXreferral_illustration,
} from './images/referral_illustration.svg';
import request_icon, {
  ReactComponent as JSXrequest_icon,
} from './images/request_icon.svg';
import saved_credentials_icon, {
  ReactComponent as JSXsaved_credentials_icon,
} from './images/saved_credentials_icon.svg';
import search_icon, {
  ReactComponent as JSXsearch_icon,
} from './images/search_icon.svg';
import security_icon, {
  ReactComponent as JSXsecurity_icon,
} from './images/security_icon.svg';
import settings_icon, {
  ReactComponent as JSXsettings_icon,
} from './images/settings_icon.svg';
import silver_status, {
  ReactComponent as JSXsilver_status,
} from './images/silver_status.svg';
import star_dollar_icon, {
  ReactComponent as JSXstar_dollar_icon,
} from './images/star_dollar_icon.svg';
import star_dollar_inverted_icon, {
  ReactComponent as JSXstar_dollar_inverted_icon,
} from './images/star_dollar_inverted_icon.svg';
import star_icon, {
  ReactComponent as JSXstar_icon,
} from './images/star_icon.svg';
import success_badge, {
  ReactComponent as JSXsuccess_badge,
} from './images/success_badge.svg';
import success_icon, {
  ReactComponent as JSXsuccess_icon,
} from './images/success_icon.svg';
import swap_icon, {
  ReactComponent as JSXswap_icon,
} from './images/swap_icon.svg';
import switcher_badge, {
  ReactComponent as JSXswitcher_badge,
} from './images/switcher_badge.svg';
import time_icon, {
  ReactComponent as JSXtime_icon,
} from './images/time_icon.svg';
import verification_pending_icon, {
  ReactComponent as JSXverification_pending_icon,
} from './images/verification_pending_icon.svg';
import verify_bonus, {
  ReactComponent as JSXverify_bonus,
} from './images/verify_bonus.svg';
import verify_bonus_icon, {
  ReactComponent as JSXverify_bonus_icon,
} from './images/verify_bonus_icon.svg';
import verify_factor3x, {
  ReactComponent as JSXverify_factor3x,
} from './images/verify_factor3x.svg';
import verify_factor4x, {
  ReactComponent as JSXverify_factor4x,
} from './images/verify_factor4x.svg';
import verify_factor5x, {
  ReactComponent as JSXverify_factor5x,
} from './images/verify_factor5x.svg';
import verify_factor6x, {
  ReactComponent as JSXverify_factor6x,
} from './images/verify_factor6x.svg';
import verify_factor7x, {
  ReactComponent as JSXverify_factor7x,
} from './images/verify_factor7x.svg';
import verify_profile_icon, {
  ReactComponent as JSXverify_profile_icon,
} from './images/verify_profile_icon.svg';
import warning_icon, {
  ReactComponent as JSXwarning_icon,
} from './images/warning_icon.svg';
import withdraw_success, {
  ReactComponent as JSXwithdraw_success,
} from './images/withdraw_success.svg';

export const images: ImageMap = {
  accountIcon: { path: account_icon, component: JSXaccount_icon },
  apiIcon: { path: api_icon, component: JSXapi_icon },
  apiIllustration: { path: api_illustration, component: JSXapi_illustration },
  apiNewIcon: { path: api_new_icon, component: JSXapi_new_icon },
  assistIcon: { path: assist_icon, component: JSXassist_icon },
  bankCard: { path: bank_card, component: JSXbank_card },
  bonusBalanceIcon: {
    path: bonus_balance_icon,
    component: JSXbonus_balance_icon,
  },
  bonusHistoryIcon: {
    path: bonus_history_icon,
    component: JSXbonus_history_icon,
  },
  bonusIcon: { path: bonus_icon, component: JSXbonus_icon },
  bonusNavIcon: { path: bonus_nav_icon, component: JSXbonus_nav_icon },
  bookMarkIcon: { path: book_mark_icon, component: JSXbook_mark_icon },
  bronzeStatus: { path: bronze_status, component: JSXbronze_status },
  cardIcon: { path: card_icon, component: JSXcard_icon },
  chartLineIcon: { path: chart_line_icon, component: JSXchart_line_icon },
  checkIcon: { path: check_icon, component: JSXcheck_icon },
  closeIcon: { path: close_icon, component: JSXclose_icon },
  contactsIcon: { path: contacts_icon, component: JSXcontacts_icon },
  copyIcon: { path: copy_icon, component: JSXcopy_icon },
  courseIcon: { path: course_icon, component: JSXcourse_icon },
  creditCardIcon: { path: credit_card_icon, component: JSXcredit_card_icon },
  crossArrowsIcon: { path: cross_arrows_icon, component: JSXcross_arrows_icon },
  crossArrowsIconSecondary: {
    path: cross_arrows_icon_secondary,
    component: JSXcross_arrows_icon_secondary,
  },
  cryptoCheckIcon: { path: crypto_check_icon, component: JSXcrypto_check_icon },
  cryptoCheckIllustration: {
    path: crypto_check_illustration,
    component: JSXcrypto_check_illustration,
  },
  cryptoImage: { path: crypto_image, component: JSXcrypto_image },
  crystal: { path: crystal, component: JSXcrystal },
  crystals: { path: crystals, component: JSXcrystals },
  crystals100x: { path: crystals100x, component: JSXcrystals100x },
  crystals1x: { path: crystals1x, component: JSXcrystals1x },
  crystals20x: { path: crystals20x, component: JSXcrystals20x },
  crystalsSuccess: { path: crystals_success, component: JSXcrystals_success },
  dashboardIcon: { path: dashboard_icon, component: JSXdashboard_icon },
  deleteIcon: { path: delete_icon, component: JSXdelete_icon },
  diamondAmlIcon: { path: diamond_aml_icon, component: JSXdiamond_aml_icon },
  diamondIcon: { path: diamond_icon, component: JSXdiamond_icon },
  diamondStatus: { path: diamond_status, component: JSXdiamond_status },
  discountEmptyIcon: {
    path: discount_empty_icon,
    component: JSXdiscount_empty_icon,
  },
  downArrowIcon: { path: down_arrow_icon, component: JSXdown_arrow_icon },
  editIcon: { path: edit_icon, component: JSXedit_icon },
  emptyTableIllustration: {
    path: empty_table_illustration,
    component: JSXempty_table_illustration,
  },
  errorIcon: { path: error_icon, component: JSXerror_icon },
  examCardIcon: { path: exam_card_icon, component: JSXexam_card_icon },
  exchangeHistoryIcon: {
    path: exchange_history_icon,
    component: JSXexchange_history_icon,
  },
  exchangeIcon: { path: exchange_icon, component: JSXexchange_icon },
  factor1x: { path: factor1x, component: JSXfactor1x },
  factor2x: { path: factor2x, component: JSXfactor2x },
  factor3x: { path: factor3x, component: JSXfactor3x },
  factor4x: { path: factor4x, component: JSXfactor4x },
  factor5x: { path: factor5x, component: JSXfactor5x },
  factor6x: { path: factor6x, component: JSXfactor6x },
  factor7x: { path: factor7x, component: JSXfactor7x },
  faqIcon: { path: faq_icon, component: JSXfaq_icon },
  fileIcon: { path: file_icon, component: JSXfile_icon },
  fileTextIcon: { path: file_text_icon, component: JSXfile_text_icon },
  fortuneWheel: { path: fortune_wheel, component: JSXfortune_wheel },
  giftIcon: { path: gift_icon, component: JSXgift_icon },
  goldStatus: { path: gold_status, component: JSXgold_status },
  graph: { path: graph, component: JSXgraph },
  heartIcon: { path: heart_icon, component: JSXheart_icon },
  infoIcon: { path: info_icon, component: JSXinfo_icon },
  infoIconSecondary: {
    path: info_icon_secondary,
    component: JSXinfo_icon_secondary,
  },
  jivoMobile: { path: jivo_mobile, component: JSXjivo_mobile },
  keyReturnIcon: { path: key_return_icon, component: JSXkey_return_icon },
  livenessIcon: { path: liveness_icon, component: JSXliveness_icon },
  loader: { path: loader, component: JSXloader },
  logo: { path: logo, component: JSXlogo },
  logoMobile: { path: logo_mobile, component: JSXlogo_mobile },
  lottery: { path: lottery, component: JSXlottery },
  magicWandIcon: { path: magic_wand_icon, component: JSXmagic_wand_icon },
  mainBg: { path: main_bg, component: JSXmain_bg },
  mainBgMobile: { path: main_bg_mobile, component: JSXmain_bg_mobile },
  moneyCirculationIcon: {
    path: money_circulation_icon,
    component: JSXmoney_circulation_icon,
  },
  moreMenuIcon: { path: more_menu_icon, component: JSXmore_menu_icon },
  notVerifyFactor1x: {
    path: not_verify_factor1x,
    component: JSXnot_verify_factor1x,
  },
  notVerifyFactor2x: {
    path: not_verify_factor2x,
    component: JSXnot_verify_factor2x,
  },
  notVerifyFactor3x: {
    path: not_verify_factor3x,
    component: JSXnot_verify_factor3x,
  },
  notVerifyFactor4x: {
    path: not_verify_factor4x,
    component: JSXnot_verify_factor4x,
  },
  notVerifyFactor5x: {
    path: not_verify_factor5x,
    component: JSXnot_verify_factor5x,
  },
  notificationBonuses: {
    path: notification_bonuses,
    component: JSXnotification_bonuses,
  },
  notificationCrypto: {
    path: notification_crypto,
    component: JSXnotification_crypto,
  },
  notificationGiveaway: {
    path: notification_giveaway,
    component: JSXnotification_giveaway,
  },
  notificationSystem: {
    path: notification_system,
    component: JSXnotification_system,
  },
  notificationTelegram: {
    path: notification_telegram,
    component: JSXnotification_telegram,
  },
  partnersIcon: { path: partners_icon, component: JSXpartners_icon },
  passportIcon: { path: passport_icon, component: JSXpassport_icon },
  percent: { path: percent, component: JSXpercent },
  phoneProfileIllustration: {
    path: phone_profile_illustration,
    component: JSXphone_profile_illustration,
  },
  piggyIcon: { path: piggy_icon, component: JSXpiggy_icon },
  platinumStatus: { path: platinum_status, component: JSXplatinum_status },
  profileIcon: { path: profile_icon, component: JSXprofile_icon },
  qrIcon: { path: qr_icon, component: JSXqr_icon },
  recentExchangesIcon: {
    path: recent_exchanges_icon,
    component: JSXrecent_exchanges_icon,
  },
  redirectIcon: { path: redirect_icon, component: JSXredirect_icon },
  referralIllustration: {
    path: referral_illustration,
    component: JSXreferral_illustration,
  },
  requestIcon: { path: request_icon, component: JSXrequest_icon },
  savedCredentialsIcon: {
    path: saved_credentials_icon,
    component: JSXsaved_credentials_icon,
  },
  searchIcon: { path: search_icon, component: JSXsearch_icon },
  securityIcon: { path: security_icon, component: JSXsecurity_icon },
  settingsIcon: { path: settings_icon, component: JSXsettings_icon },
  silverStatus: { path: silver_status, component: JSXsilver_status },
  starDollarIcon: { path: star_dollar_icon, component: JSXstar_dollar_icon },
  starDollarInvertedIcon: {
    path: star_dollar_inverted_icon,
    component: JSXstar_dollar_inverted_icon,
  },
  starIcon: { path: star_icon, component: JSXstar_icon },
  successBadge: { path: success_badge, component: JSXsuccess_badge },
  successIcon: { path: success_icon, component: JSXsuccess_icon },
  swapIcon: { path: swap_icon, component: JSXswap_icon },
  switcherBadge: { path: switcher_badge, component: JSXswitcher_badge },
  timeIcon: { path: time_icon, component: JSXtime_icon },
  verificationPendingIcon: {
    path: verification_pending_icon,
    component: JSXverification_pending_icon,
  },
  verifyBonus: { path: verify_bonus, component: JSXverify_bonus },
  verifyBonusIcon: { path: verify_bonus_icon, component: JSXverify_bonus_icon },
  verifyFactor3x: { path: verify_factor3x, component: JSXverify_factor3x },
  verifyFactor4x: { path: verify_factor4x, component: JSXverify_factor4x },
  verifyFactor5x: { path: verify_factor5x, component: JSXverify_factor5x },
  verifyFactor6x: { path: verify_factor6x, component: JSXverify_factor6x },
  verifyFactor7x: { path: verify_factor7x, component: JSXverify_factor7x },
  verifyProfileIcon: {
    path: verify_profile_icon,
    component: JSXverify_profile_icon,
  },
  warningIcon: { path: warning_icon, component: JSXwarning_icon },
  withdrawSuccess: { path: withdraw_success, component: JSXwithdraw_success },
};
