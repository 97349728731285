import * as React from 'react';
import { SVGProps } from 'react';

const SvgWarningIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="#fff"
        style={{
          mixBlendMode: 'multiply',
        }}
        d="M0 0h20v20H0z"
      />
      <path fill="#fff" d="M5 3.75h10v12.5H5z" />
      <path
        fill="#fff"
        style={{
          mixBlendMode: 'multiply',
        }}
        d="M0 0h20v20H0z"
      />
      <path
        d="M10 1.25c-4.813 0-8.75 3.938-8.75 8.75 0 4.813 3.938 8.75 8.75 8.75 4.813 0 8.75-3.938 8.75-8.75 0-4.813-3.938-8.75-8.75-8.75ZM9.312 5h1.376v6.875H9.312V5ZM10 15.625a.961.961 0 0 1-.938-.938c0-.5.438-.937.938-.937.5 0 .938.438.938.938 0 .5-.438.937-.938.937Z"
        fill="#EF4444"
      />
    </svg>
  );
};
export default SvgWarningIcon;
